import {Component,EventEmitter,Input,OnChanges,OnInit,Output,SimpleChanges,} from '@angular/core';
import {DiscountTypePer,DiscountTypeRupees,OfflineSchemeId,SchemeApplyOnProduct,} from 'src/app/core/constants/common-constant';

@Component({
  selector: 'app-sale-price',
  templateUrl: './sale-price.component.html',
  styleUrls: ['./sale-price.component.scss'],
})
export class SalePriceComponent implements OnInit, OnChanges {
  @Input() productData: any = {};
  @Input() page: any = '';
  @Output('sellPriceEmit') emitter = new EventEmitter();
  @Input() qty: any = 1;
  @Input() userType = 19;
  @Input() shopId: any = {};
  DiscountTypePercentage: any = DiscountTypePer;
  sellingPrice: any = 0;
  noData: boolean = false;
  infoShow: boolean = false;
  constructor() { }

  userData: any = {};
  isB2B: boolean = false;
  sellingPriceExDiscount = 0;
  ngOnInit(): void {
    console.log(this.productData, "on init")

  }

  data: any = [];
  qtyCheck: number = 1;
  ngOnChanges(changes: SimpleChanges): void {
    console.log(
      this.productData,
      'prooduct data',
      this.qty,
      this.userType,
      changes
    );
    if (this.userType == 21) {
      this.isB2B = true;
    } else {
      this.isB2B = false;
    }

    if (Object.entries(this.productData).length > 0) {
      this.qty = this.qty;
      this.sellingPriceExDiscount = this.isB2B
        ? this.productData.tradeDiscountPrice //distributionBeforeTaxRate//distributionAfterTaxRate
        : this.productData.enterSalesRate;
      //afterTaxRate;
      this.noData = true;
      // this.productData.schemeData=[]
      this.data =
        this.productData.resultCustomerForScheme ||
        this.productData.resultForScheme;
      //  console.log(this.data,"data scheme")
      if (this.data?.length > 0) {
        this.data = this.data.sort((a, b) => a.schemeId - b.schemeId);
        let offlineScheme: any = false;
        let ShopWiseApply: boolean = false;
        this.data.forEach((e) => {
          e.discountPlatformEnumId = e.discountPlatformEnumId.map(
            (e) => (e = Number(e))
          );
          e.shopId = e.shopId.map((e) => (e = Number(e)));
          offlineScheme = e.discountPlatformEnumId.includes(OfflineSchemeId);
          ShopWiseApply = e.shopId.includes(this.shopId);
        });
        if(this.page == 'POS'){
           console.log(offlineScheme)
        }

        if (offlineScheme) {
          // if scheme for offline
          this.sellingPrice = this.isB2B
            ? this.productData?.tradeDiscountPrice  //distributionBeforeTaxRate//distributionBeforeTaxRate
            : this.productData.enterSalesRate ;
          this.checkDiscountType(this.data);
        } else {
          this.setSchemeValue();
        }
      } 
      else {
        this.setSchemeValue();
      }
    }
  }


  setSchemeValue() {
    // before tax rate 
    let price = 
      (this.isB2B
        ? this.productData?.tradeDiscountPrice//distributionBeforeTaxRate//distributionAfterTaxRate
        : this.productData?.enterSalesRate);
    this.sellingPrice = price
    this.productData.salePriceWithScheme = this.sellingPrice;

    // for scheme tax Rate
    this.productData.schemeTaxRate =
      (this.isB2B
        ? ((this.productData.salePriceWithScheme * this.productData.taxPercent) / 100)
        : ((this.productData.salePriceWithScheme * this.productData.taxPercent) / 100)).toFixed(2); //Number(taxRate).toFixed(2)

    let schemeAfterTaxRate: any = (this.isB2B
      ? (Number(this.productData?.tradeDiscountPrice) + Number(this.productData.schemeTaxRate))
      : ((Number(this.productData.salePriceWithScheme) + Number(this.productData.schemeTaxRate))));
   // After Tax Rate
    this.productData.schemeAfterTaxRate = (schemeAfterTaxRate);

    // scheme Amount
    this.productData.schemeDiscountAmount = ((this.isB2B
      ? this.productData?.tradeDiscountPrice
      : this.productData?.enterSalesRate) - this.productData.salePriceWithScheme).toFixed(2);
    this.emitter.emit(this.sellingPrice);
  }

  disPer: any = [];
  disRupee: any = [];
  totalDiscountInPercentage: number = 0;
  totalDiscountInRupee: number = 0;
  productTaxPer = 0;

  checkDiscountType(scheme: any = []) {
    scheme.sort((a, b) => {
      return a.discountTypeEnumId - b.discountTypeEnumId;
    });
    scheme.forEach((e) => {
      let ShopWiseApply = e.shopId.includes(this.shopId);
      console.log(ShopWiseApply, "shopWise")
      if (ShopWiseApply) {
        if (Number(e.schemeApplyOnEnumId) == SchemeApplyOnProduct) {
          this.productTaxPer = this.productData.taxPercent | 0;

          this.sellingPriceExDiscount = this.isB2B
            ? e.distributionAfterTaxRate
            : e.afterTaxRate;
          if (Number(e.discountTypeEnumId) == Number(DiscountTypeRupees)) {
            this.totalDiscountInRupee =
              Number(this.totalDiscountInRupee) + Number(e.discountAmount);
          } else if (
            Number(e.discountTypeEnumId) == Number(DiscountTypePer)
          ) {
            this.totalDiscountInPercentage =
              Number(this.totalDiscountInPercentage) + Number(e.discountAmount);
          }
          this.calculateDiscount(e);
        }
        else {
          // console.log(e, "e.schemeApplyOnEnumId", e.schemeApplyOnEnumId)
        }
      }
    });
    this.addTaxPercent(this.sellingPrice);
  }

  calculateDiscount(e) {
    if (Number(this.qty) < Number(e.productQuantity)) {
      //e.productQuantity  < 1 &&
      e.applicable = true;
      return;
    }
    else {
      e.applicable = false;
      //console.log(e);
      if (Number(e.discountAmount) !== 0 || e.roductWiseDiscountAmount !== 0) {
        console.log(e.productDiscountTypeEnumId == Number(DiscountTypeRupees));
        if (Number(e.productDiscountTypeEnumId) == Number(DiscountTypeRupees)) {
          // For Rupees
          this.sellingPrice = (
            this.sellingPrice - Number(e.roductWiseDiscountAmount)
          ).toFixed(2);
        }
        else if (
          Number(e.discountTypeEnumId) == Number(DiscountTypePer)
        ) {
          // for Percentange
          this.sellingPrice = (
            Number(this.sellingPrice) -
            Number((this.sellingPrice * e.discountAmount) / 100)).toFixed(2);
        }
        else {
          // console.log(this.sellingPrice, 'not ');
        }
      }
    }
  }

  addTaxPercent(sellingPrice) {
    this.productData.salePriceWithScheme = Number(sellingPrice) ;
    let taxRate = (
      (Number(sellingPrice) * Number(this.productData.taxPercent)) /100).toFixed(2);
    let schemeAfterTaxRate = (Number(sellingPrice) + Number(taxRate)).toFixed(2);
    // after tax Amount
    this.productData.schemeAfterTaxRate = schemeAfterTaxRate;
    //tax Amount
    this.productData.schemeTaxRate = Number(taxRate).toFixed(2);
    // scheme discount
    this.productData.schemeDiscountAmount = ((this.isB2B
      ? this.productData?.tradeDiscountPrice
      : this.productData?.enterSalesRate) - this.productData.salePriceWithScheme).toFixed(2);
    this.emitter.emit(this.productData);
    console.log(this.productData, "this.productData")
    return this.productData;
  }
}
