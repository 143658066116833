<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author:  Monika Tiwari
 Original Date: 29 Sep 2021 --> 
<!-- Brand List Main Body Section Start-->
<div class="brand_content">
    <div class="row">
        <div class="col-lg-3  col-md-3 col-sm-6" *ngFor="let obj of data">
            <mat-card class="brandCard" title="{{obj.brandName}}">
                <mat-card-content>
                    <figure>
                        <img [src]="obj.getSignedUrlForImage" alt="brand" class="brandLogo"
                            (click)="onActionHandler(obj,'view')">
                    </figure>
                    <article>
                        <h4>{{obj.brandName}}</h4>
                        <span>{{obj.gredes}} Grades</span>
                    </article>
                    <div class="action">
                        <button mat-icon-button class="edit">
                            <mat-icon fontSet="material-icons-outlined" (click)="onActionHandler(obj,'edit')">edit
                            </mat-icon>
                        </button>
                        <button mat-icon-button class="visibility">
                            <mat-icon fontSet="material-icons-outlined" (click)="onActionHandler(obj,'view')">visibility
                            </mat-icon>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>