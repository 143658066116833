
// TABLE ACTIONS
export const EDIT = 'edit';
export const DELETE = 'delete';
export const VIEW = 'visibility'
export const DONE = 'done_all';
export const PAYMENT = 'payment';
export const DOWNLAOD = 'get_app';
export const ADD = 'add';
export const SENT = 'send';

//master Name  to get  data
export const MASTER = {
  CERTIFICATE: "Certificate",
  COLOUR: "Colour",
  METALGEM: "Metalgem",
  PURITY: "Purity",
  CATEGORY: "Category",
  PATTERN: "Pattern",
  TYPE: "Type",
  SHAPE: "Shape",
  TAX:"Tax",
  BRAND:"Brand",
  BANNER:"Banner",
  DOCUMENT:"Document",
  EXPENSES:"Expenses"

}

export const userAccess = {
  DASHBOARD:"Dashboard",
  INVENTORYNAME:"Inventory",
  INVENTORY:{
    CATEGORY:"Category",
    TYPE:"Type",
    BRAND:"Brand",
    PRODUCT:"Product"
  },
  MASTERNAME:"Master",
  MASTER:{
    TAXCATEGORY: "TaxCategory",
    DOCUMENTS:"Documents",
    BODYCOLOR:"BodyColor",
    LIGHTCOLOR: "LightColor",
    SHOPMASTER: "ShopMaster",
    DESIGNATION:"Designation",
    TRANSPOTER:"transpoter",
    EXPENSES:"Expenses",
  },
  MARKETINGNAME:"Marketing",
  MARKETING:{
    BANNER:"Banner"
  },
  USERNAME:"User",
  USER: {
    B2BCUSTOMER:"B2BCustomer",
    VENDOR:"Vendor",
    USERACCESS: "UserAccess",
    EMPLOYEE: "Employee"
  },
  PURCHASENAME: "Purchase",
  PURCHASE : {
    PURCHASEORDER:"PurchaseOrder",
    INWARD:"Inward",
    BILLS:"Inward"

  },
  ORDERNAME:"Order",
  ORDER: {
    ORDERALLOCATION:"OrderAllocation",
    ORDERMANAGEMENT:"OrderManagement"
  },
  ACTIONS: {
    ADD: 'add',
    VIEW: 'view',
    EDIT: 'edit',
    DELETE: 'delete'
  }

}

export const DESCRIPTION_FIELD_LIMIT = 500
export   const  SHORT_DESCRIPTION_FIELD_LIMIT = 200
export   const  NAME_FIELD_MAX_LIMIT = 100
export   const  NAME_FIELD_MIN_LIMIT = 100


// history table coulmnName
export const HISTORY_TABLE_COLUMN = [
  {
    key: 'sno', display: 'S.No.', config: { isIndex: true }

  },
  {
    key: 'updatedLoginUserName', display: 'Edited By' , sort: true, 

  },
  {
    key: 'updatedOnDate', display: 'Edited Date', sort: true,config: { isDate: true, format: 'dd-MM-yyyy' }

  },
  {
    key: 'actionRemarks', display: 'Remark' , sort: true, 

  },
  {
    key: 'status', display: 'Status', sort: true,config: {isStatus :true}

  },
]


export const RADIO_ITEM = [
  { id: 1, name: 'Active' },
  { id: 2, name: 'Deactive' },

]

export const REVIEW_ITEMS = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
export const RATING_ITEMS = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]

export const DOOR_ITEMS = [
  { id: 1, name: 'Indoor' },
  { id: 2, name: 'Outdoor' },
]
export const HAS_WARRANTY_ITEMS = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
export const WARRANTY_FORI_ITEMS = [
  { id: 1, name: 'Manufacturer' },
  { id: 2, name: 'Seller' },
]

export const UNIT_ITEMS = [
  {
    id: 1,
    name: 'Day'
  },
  {
    id: 2,
    name: 'Month'
  },
  {
    id: 3,
    name: 'Year'
  }
]

//expport const OPTIONAL
export const APPLY_CONDITION = [
  { id: 33, name: 'Optional' },
  { id: 32, name: 'Mandatory' }
]

export const PUBLISHED_ID = [
  { id: 26, name: 'Published' },
  { id: 25, name: 'Unpublished' }
]

export const PAID_STATUS = [
  { id: 26, name: 'Paid' },
  { id: 25, name: 'UnPaid' }
]

export const RELATIONSHIP_STATUS = [
  { id: 30, name: 'Married' },
  { id: 31, name: 'UnMarried' },
]

export const RELATIONSHIP_STATUS_FOR_B2B = [
  { id: 47, name: 'Married' },
  { id: 48, name: 'UnMarried' },
]

export const CREDIT_LIMIT_ITEM = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
]

export const CURRENCY_DATA = [
  { id: 1, name: '₹' }
]







export const USER_TYPE = {
  VENDOR: 33,
  EMPLOYEE: 31,
  SELLER: 36,
  COMPANY: 32,
  ADMIN: 3,
  CUSTOMER: 4
}
export const ACTIVE_DE_ACTIVE_STATUS_ID = {
  ACTIVE: 1,
  DEACTIVE: 2,
  ALL: 0
}

export const USER_STATUS_ENUM_ID = {
  PENDING: 16,
  APPROVED: 17,
  REJECTED: 18

}

export const GENDER_DATA = [
  { id: 13, name: 'Male' },
  { id: 14, name: 'Female' },
  { id: 76, name: 'Unisex' },
  {id: 23, name: 'Others'}
]

export  const PAYMENT_MODE = [
    { id: 69, name: 'NEFT' },
    { id: 70, name: 'RTGS' },
    { id: 71, name: 'UPI' },
    { id: 72, name: 'Cash' },
    { id: 73, name: 'Cheque' },
]

export  const PAYMENT_MODE_PAYNOW = [
  { id: 71, name: 'UPI' },
  { id: 72, name: 'Cash' },
 
]

export const POS_INVOICE= [
  { id: 1, name: 'Invoice Thermal Paper' },
  { id: 2, name: 'Invoice A4 Paper' },
]

export  const RETRUN_TYPE = [
  {
    id:74,name:'Sale Return'
  },
  {
     id:75, name:'Sale Challan Return'
  }
  

]

export  const  BECOME_PARTNER_STATUS = [
  {
    id: 19, name: 'B2C'
  },
  {
    id: 21, name: 'B2B'
  }

]

export const PAYMENT_MODE_ID = {
  NEFT: 69,
  RTGS: 70,
  UPI: 71,
  CASH: 72,
  CHAQUE: 73,
}

export const ADVACE_OR_DEPOSIT_ID = {
ADVANCEID :'80',
DEPOSITID :'81'
}

export const REASON_TO_RETURN = [
  {
    id:79,name:'Damage'
  },
  {
     id:78, name:'Non Sale Able'
  },
  {
    id:77, name:'Sale Able'
 }
]

export const TRANSPOTER_TYPE_ID =[
  {
    id:83, name: 'Self Dispatched'
  },
  {
    id:84, name: 'Transpoter '
  }
]

export const IS_CREADITLIMIT = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
]

export const CURRANCYDATA = [
  { id: 1, name: '₹' }
]

export const INDIACOUNTRYID = 0


//expense master enum id
export const EXPENESES_TYPEID = [
  {
    id: 87,
    type: 'Direct Expenses'
  },
  {
    id: 88,
    type: 'Indirect Expenses'
  }
]

export const EXPENESES_SUB_TYPEID= [
  {
    id: 89,
    type: 'Operating Expenses'
  },
  {
    id: 90,
    type: 'Non-Operating Expenses'
  },
  {
    id: 91,
    type: 'Financial Expenses'
  },
  {
    id: 92,
    type: 'Extraordinary Expenses'
  }
]


export const PAYMENT_STATUS =[
  {
    id:95,
    name :"Cash"
  },
  {
    id:96,
    name :"Liability"
  }
]
 

// export dynamic POS Invoice
export interface IPosInvoice{
  productName :string;
  qty : number;
  posPrice:number;
  schemeAfterTaxRate:number;
}

// export dynamic Challan
export interface IChallanPdf{
  productName :string;
  qty : number;
  afterTaxRate:number;
  schemeAfterTaxRate:number
}

// export dynamic invoice table data 
export interface InvoiceHSNTableData{
  CGST: number;
  HsnCode: string;
  IGST: number;
  SGST: number;
  totalTax: number;
  qty: number;
  taxRate: number;
  taxableValue: number;
  totalsum:number;
}
// export dynamic invoice table data 
export interface InvoiceTable{
  // gradeDetail: any;
  // cr-Note-table
  returnQuantity:number
  enterSalesRate:number
  invoiceTotalAmount:number

  balanceAmount:number,
  discountPercent:number,
  totaldiscAmount:number,



  taxable: number;
  Total: number
  afterTaxRate: number;
  beforTaxRate: number;
  brandName: string;
  code: string;
  discountPer: string;
  dispatchQuantity: string;
  hsnCode: string;
  inStockQuantity: string;
  invoiceDetailId: number;
  invoiceId: number;
  mrp: string;
  noOfProduct: string;
  orderId: string;
  productId: number;
  productName: string;
  remainingOrderQuantity: string;
  remarks: string;
  taxPercent: string;
  taxRate:string;
  freeNoOfItem:string;
  saleDiscount:string;
  gstItem: {IGST: number, CGST: number, SGST: number},
  unitPrice:number;
  
  gradeDetail:{
     gradePercentage:number
  }
  tradePer:number

  
    "invoice_detail_id": number,
    "product_name": string,
    // "code": "9405",
    "brand_name": string,
    "brand_id": number,
    "hsn_code": string,
    "image": string,
    "invoice_id":number,
    "product_id": number,
    "dispatch_quantity": number,
    "per_product_price": Number,
    "tax_amount": Number,
    "free_no_of_item": Number,
    "sale_discount": Number,
    "scheme_discount":Number,
    "remark": string,
    "scheme_discount_type":Number,
    "scheme_discount_type_status": string,
    "tax_percent":Number,
    "status_enum_id": Number,
    "in_stock_quantity": Number,
    "committed_stock": Number,
    "remaining_stock_quantity":Number,
    "remaining_order_quantity": Number,
    "sale_discount_type": Number,
    "sale_discount_type_status": Number,
    // "productName": "CFL Bulb",
    // "brandName": "Havells ",
    "Qty": Number,
    "Rate": Number,
    // "productId": 286,
    "FreeQty": Number,
    "Discount": Number,
    "sch_Discount":Number,
    "tax": Number,
    "Remark": Number,
    "taxAmount": Number,
    "taxAmountQty": Number,
    "saleAmount": Number


}


// 58;"Order Type";"Normal"
// 59;"Order Type";"Project"



// 62;"Order Name";"Cutomer Order"
// 61;"Order Name";"Seles Order"




export interface ILedgerPdf{
  date :string;
  type : string;
  Particular:number;
  debit:number;
  credit:number;
  bal:number;
  voucher_name:number,
  voucher_id:Number;
  outstanding_amount:Number

}


// 120	"DiscountPlatform"	"Offline"
  // 119	"DiscountPlatform"	"Online "
  // 118	"SchemeFor"	"Product "
  // 117	"SchemeFor"	"Category"
  // 116	"SchemeFor"	"Brand"
  // 115	"SchemeApplyOn"	"Invoice"
  // 114	"SchemeApplyOn"	"Product"
  // 113	"DiscountType"	"percentage"
  // 112	"DiscountType"	"rupee"
//   123	"RateChangeReason"	"Other"
// 122	"RateChangeReason"	"Need Update"
// 121	"RateChangeReason"	"incorrect purchase rate"
// 123	"RateChangeReason"	"Other"
// 122	"RateChangeReason"	"Need Update"
// 121	"RateChangeReason"	"incorrect purchase rate"
// 120	"DiscountPlatform"	"Offline"
// 119	"DiscountPlatform"	"Online "
// 118	"SchemeFor"	"Product "
// 117	"SchemeFor"	"Category"
// 116	"SchemeFor"	"Brand"
// 115	"SchemeApplyOn"	"Invoice"
// 114	"SchemeApplyOn"	"Product"
// 113	"DiscountType"	"percentage"
// 112	"DiscountType"	"rupee"
// 111	"Order Type"	"Back Date Invoice"

export const rateChangeDropdown = 'RateChangeReason'
export const SchemeApplyOnDropdown = 'SchemeApplyOn'
export const DiscountTypeDropdown = 'DiscountType'
export const DiscountPlatformDropdown = 'DiscountPlatform'
export const SchemeForDropdown = 'SchemeFor'
export const rateChangeDropdown_Other_ID = 123
export const SchemeApplyOnInvoice = 115
export const SchemeApplyOnProduct = 114
export const DiscountTypePer =113
export const DiscountTypeRupees =112
export const SchemeForProForProduct = 118
export const SchemeForProForCategory = 117
export const SchemeForProForBrand = 116
export const SchemeForSingleProduct =124
export const DiscountPlatformOffLine = 120
export const DiscountPlatformOnLine = 119

export const discountType = 'DiscountType'
export const schemeApplyOn = 'SchemeApplyOn'
export const schemeFor ='SchemeFor'
export const discountPlatform = 'DiscountPlatform'

export const b2bEnumTypeId=21
export const b2cEnumTypeId=19

export const schemeModalTypeCustomer = 'Customer'
export const schemeModalTypeProduct = 'Product'

export const OfflineSchemeId = 120
export const TABlE_ROW =100
