import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  remark : boolean = false
  remarkForm: FormGroup
  submitted:boolean = false
  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.remarkForm = this.formBuilder.group({
      remark : ['',Validators.required]
    })
    this.remark = this.data.remark
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  yesClick() {
    this.submitted = true
    this.dialogRef.close(true);
    
    
  }
  noClick() {
    this.dialogRef.close(false);

  }
}