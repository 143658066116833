<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author: Aman Mishra
 Original Date: 3 June 2021 -->

<mat-sidenav-container class="sidenav-container" autosize>
    <mat-sidenav #sidenav class="sidenav" [ngClass]="isExpanded ? 'open' : 'close'" mode="side" opened="true"
        (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
        <!--  <button mat-icon-button (click)="burgerClick()"><mat-icon>menu</mat-icon></button> -->
        <figure class="logo p-2" (click)="gotoDashboard()">
            <div class="fullogo">
                <div class="d-flex justify-content-center">
                    <img src="/assets/images/jb_logo.png" alt="logo">
                    <h2 class="mb-0 logoname">Lights</h2>
                </div> 
            </div> 
            <img src="/assets/images/logo_jb.png" alt="icon" class="logoicon" height="43"><!-- *ngIf="!isExpanded" -->
        </figure>
        <mat-nav-list>
            <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
        </mat-nav-list>
        <!-- <hr class="border_hr"> -->
        <div class="footer-sidebar">
            <span>Copyright © 2021 Kritin Digital Solutions All rights reserved.</span>
        </div>
    </mat-sidenav>
    <div class="sidenav-content">
        <app-header></app-header>
        <router-outlet></router-outlet>
    </div>
    <!-- <app-footer></app-footer> -->
</mat-sidenav-container>