//===============================================================================
// © 2021 .Kritin Digital solutions  All rights reserved.
// Original Author: Aman Mishra
// Original Date: 3 June 2021
//==============================================================================

import { NavItem } from './../../shared/interface/nav-item';
import { Component, Input, OnInit } from '@angular/core';
import { DataShareService } from 'src/app/core/services/data-sharing.service';
import { userAccess } from 'src/app/core/constants/common-constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  showSubmenu: boolean = false;
  isExpanded: boolean;
  isShowing: boolean = false;
  sidebar = true;
  // remove that json need to come from  apis
  navItems: NavItem[] = [
    {
      displayName: 'Dashboard',
      iconName: 'dashboard',
      route: 'main',
      accessControl: userAccess.DASHBOARD,
      isSelected: false
    },
    {
      displayName: 'Inventory',
      iconName: 'inventory',
      route: 'maleficent',
      accessControl: userAccess.INVENTORYNAME,
      isSelected: false,
      children: [
        {
          displayName: 'Category Group',
          iconName: 'category',
          route: 'main/in/category',
          accessControl: userAccess.INVENTORY.CATEGORY,
          isSelected: false
        },
        {
          displayName: 'Category Type',
          iconName: 'border_color',
          route: 'main/in/type',
          accessControl: userAccess.INVENTORY.TYPE,
          isSelected: false
        },
        {
          displayName: 'Brand',
          iconName: 'filter_vintage',
          route: 'main/in/brand',
          accessControl: userAccess.INVENTORY.BRAND,
          isSelected: false
        },

        {
          displayName: 'Product',
          iconName: 'inventory_2',
          route: 'main/in/product',
          accessControl: userAccess.INVENTORY.PRODUCT,
          isSelected: false
        },
        // {         // hide the tax master By Pankaj sir 
        //   displayName: 'Tax Category',
        //   iconName: 'receipt',
        //   route: 'main/master/tax',
        //   accessControl: userAccess.MASTER.TAXCATEGORY,
        //   isSelected: false
        // },
        {
          displayName: 'Body Color',
          iconName: 'color_lens',
          route: 'main/master/body-color',
          accessControl: userAccess.MASTER.BODYCOLOR,
          isSelected: false
        },
        {
          displayName: 'Light Color',
          iconName: 'color_lens',
          route: 'main/master/light-color',
          accessControl: userAccess.MASTER.LIGHTCOLOR,
          isSelected: false
        },
        {
          displayName: 'Family Type',
          iconName: 'color_lens',
          route: 'main/master/family-type',
          accessControl: userAccess.MASTER.TRANSPOTER,
          isSelected: false
        },
      ],
    },
    {
      displayName: 'Master',
      iconName: 'business_center',
      route: 'maleficent',
      accessControl: userAccess.MASTERNAME,
      isSelected: false,
      children: [
        // {
        //   displayName: 'Tax Category',
        //   iconName: 'receipt',
        //   route: 'main/master/tax',
        //   accessControl: userAccess.MASTER.TAXCATEGORY,
        //   isSelected: false
        // },
        {
          displayName: 'Documents',
          iconName: 'content_paste',
          route: 'main/master/documents',
          accessControl: userAccess.MASTER.DOCUMENTS,
          isSelected: false
        },
        // {
        //   displayName: 'Body Color',
        //   iconName: 'color_lens',
        //   route: 'main/master/body-color',
        //   accessControl: userAccess.MASTER.BODYCOLOR,
        //   isSelected: false
        // },
        // {
        //   displayName: 'Light Color',
        //   iconName: 'color_lens',
        //   route: 'main/master/light-color',
        //   accessControl: userAccess.MASTER.LIGHTCOLOR,
        //   isSelected: false
        // },
        {
          displayName: 'Shop Master',
          iconName: 'storefront',
          route: 'main/master/shop',
          accessControl: userAccess.MASTER.SHOPMASTER,
          isSelected: false
        },
        {
          displayName: 'Designation',
          iconName: 'label',
          route: 'main/master/designation',
          accessControl: userAccess.MASTER.DESIGNATION,
          isSelected: false
        },
        {
          displayName: 'Transpoter',
          iconName: 'local_shipping',
          route: 'main/master/transpoter',
          accessControl: userAccess.MASTER.TRANSPOTER,
          isSelected: false
        },
        {
          displayName: 'Expenses ',
          iconName: 'iso',
          route: 'main/master/expenses',
          accessControl: userAccess.MASTER.EXPENSES,
          isSelected: false
        },
        // {
        //   displayName: 'Family Type',
        //   iconName: 'color_lens',
        //   route: 'main/master/family-type',
        //   accessControl: userAccess.MASTER.TRANSPOTER,
        //   isSelected: false
        // },
        {
          displayName: 'Company',
          iconName: 'view_compact',
          route: 'main/master/company',
          accessControl: userAccess.MASTER.TRANSPOTER,
          isSelected: false
        },
      ],
    },

    {
      displayName: 'Marketing',
      iconName: 'campaign',
      route: 'maleficent',
      accessControl: userAccess.MARKETINGNAME,
      isSelected: false,
      children: [
        {
          displayName: 'Banner',
          iconName: 'panorama',
          route: 'main/marketing/banner',
          accessControl: userAccess.MARKETING.BANNER,
          isSelected: false
        },
      ]
    },

    {
      displayName: 'User',
      iconName: 'manage_accounts',
      route: 'maleficent',
      accessControl: userAccess.USERNAME,
      isSelected: false,
      children: [
        {
          displayName: 'B2B Customer',
          iconName: 'people',
          route: 'main/user/b2b',
          accessControl: userAccess.USER.B2BCUSTOMER,
          isSelected: false,
        },
        {
          displayName: 'B2C Customer',
          iconName: 'people',
          route: 'main/user/b2c',
          accessControl: userAccess.USER.B2BCUSTOMER,
          isSelected: false,
        },
        {
          displayName: 'Employee',
          iconName: 'person',
          route: 'main/user/employee',
          accessControl: userAccess.USER.EMPLOYEE,
          isSelected: false
        },
        {
          displayName: 'Vendor',
          iconName: 'person_outline',
          route: 'main/user/vendor',
          accessControl: userAccess.USER.VENDOR,
          isSelected: false
        },
        {
          displayName: 'User Access',
          iconName: 'person',
          route: 'main/user/user-access',
          accessControl: userAccess.USER.USERACCESS,
          isSelected: false
        },
      ]
    },
    {
      displayName: 'Purchase',
      iconName: 'shopping_bag',
      route: 'maleficent',
      accessControl: userAccess.PURCHASENAME,
      isSelected: false,
      children: [
        {
          displayName: 'Purchase Order',
          iconName: 'inventory_2',
          route: 'main/purchase/purchase-order',
          accessControl: userAccess.PURCHASE.PURCHASEORDER,
          isSelected: false
        },
        {
          displayName: 'Inward',
          iconName: 'move_to_inbox',
          route: 'main/purchase/inward',
          accessControl: userAccess.PURCHASE.INWARD,
          isSelected: false
        },
        {
          displayName: 'Purchase Bills',
          iconName: 'business_center',
          route: 'main/purchase/bills',
          accessControl: userAccess.PURCHASE.BILLS,
          isSelected: false
        },
        {
          displayName: 'Bills Payment',
          iconName: 'business_center',
          route: 'main/purchase/bill-payment',
          accessControl: userAccess.PURCHASE.BILLS,
          isSelected: false
        },
      ]
    },
    {
      displayName: 'Order',
      iconName: 'shop',
      route: 'maleficent',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
      children: [
        {
          displayName: 'Order-Allocation',
          iconName: 'store_mall_directory',
          route: 'main/order/order-allocation',
          accessControl: userAccess.ORDER.ORDERALLOCATION,
          isSelected: false
        },

      ]
    },
    {
      displayName: 'Shop Management',
      iconName: 'shop',
      route: 'main/order/order-management',
      accessControl: userAccess.ORDER.ORDERMANAGEMENT,
      isSelected: false
    },


    // {
    //   displayName: 'Opening Stock',
    //   iconName: 'inventory_2',
    //   route: 'main/opening-stock',
    //   accessControl: userAccess.ORDERNAME,
    //   isSelected: false,
    // },
    {
      displayName: 'Stock Query',
      iconName: 'av_timer',
      route: 'main/stock-report',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Current Stock',
      iconName: 'view_carousel',
      route: 'main/current-stock',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Sales',
      iconName: 'receipt',
      route: 'maleficent',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
      children: [
        // {
        //   displayName: 'All Sales',
        //   iconName: 'point_of_sale',
        //   route: 'main/sales/all-sales',
        //   accessControl: userAccess.ORDER.ORDERALLOCATION,
        //   isSelected: false
        // },
        {
          displayName: 'Customers',
          iconName: 'people',
          route: 'main/sales/customers',
          accessControl: userAccess.ORDER.ORDERALLOCATION,
          isSelected: false
        },
        
        {
          displayName: 'Invoices',
          iconName: 'fact_check',
          route: 'main/sales/invoices',
          accessControl: userAccess.ORDER.ORDERALLOCATION,
          isSelected: false
        },

        {
          displayName: 'Sales Analysis',
          iconName: 'inventory_2',
          route: 'main/sales/analysist',
          accessControl: userAccess.ORDER.ORDERALLOCATION,
          isSelected: false
        },
        // {
        //   displayName: 'Product',
        //   iconName: 'inventory_2',
        //   route: 'main/sales/product',
        //   accessControl: userAccess.ORDER.ORDERALLOCATION,
        //   isSelected: false
        // },
        {
          displayName: 'Receipt',
          iconName: 'receipt',
          route: 'main/sales/receipt',
          accessControl: userAccess.ORDER.ORDERALLOCATION,
          isSelected: false
        },
        {
          displayName: 'Return',
          iconName: 'assignment_return',
          route: 'main/sales/return',
          accessControl: userAccess.ORDER.ORDERALLOCATION,
          isSelected: false
        },



      ]
    },
    {
      displayName: ' Receivable / Payable List',
      iconName: 'receipt',
      route: 'main/accounting',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Credit/Debit Note',
      iconName: 'note_add',
      route: 'main/cr-dr-note',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },

    {
      displayName: 'Voucher',
      iconName: 'gavel',
      route: 'main/voucher',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Ledger',
      iconName: 'account_balance_wallet',
      route: 'main/ledger',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Sales Data Entry',
      iconName: 'swap_horizontal_circle',
      route: 'main/migration',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Purchase Data Entry',
      iconName: 'swap_horizontal_circle',
      route: 'main/purchase-data-entry',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Receivable Ageing',
      iconName: 'pages',
      route: 'main/receivables-ageing',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Rate List',
      iconName: 'receipt',
      route: 'main/rate',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Update Product Rate',
      iconName: 'receipt',
      route: 'main/product-rate-changes',
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    },
    {
      displayName: 'Scheme',
      iconName: 'receipt',
      route: 'main/scheme', 
      accessControl: userAccess.ORDERNAME,
      isSelected: false,
    }

    

  ];

  constructor(public dataSharingService: DataShareService,private router:Router) { }

  ngOnInit(): void {
    this.getValueFromHeader();
  }

  gotoDashboard(){
    this.router.navigate(['/main/'])
  }
  getValueFromHeader() {
    this.dataSharingService.sidebarObservable.subscribe(
      (data) => {
        this.isExpanded = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  burgerClick() {
    this.sidebar = !this.sidebar
    this.dataSharingService.sidebarCollapsed(this.sidebar)
  }

}
