import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  //@Output() onClick = new EventEmitter<any>();
  @Output("onAction") emitter = new EventEmitter();
  @Input() heading: string
  @Input() buttonLabel: string
  @Input() subHeading:string
  @Input() data;

  

  constructor() { }

  ngOnInit(): void {
    console.log('data', this.data)

  }

  onActionHandler(obj,action) {
    const actionData = {
      action: action,
        obj: obj
    }
    this.emitter.emit(actionData);
  }
  

  

}
