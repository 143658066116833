import { Component, OnInit } from '@angular/core';
// import * as Highcharts from 'highcharts';
// import HC_exporting from 'highcharts/modules/exporting';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {
  // Highcharts = Highcharts;

  // chartOptions!: {};
  
  // constructor() { }

  ngOnInit() {


   
  }

}


