import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-trade-discount',
  templateUrl: './trade-discount.component.html',
  styleUrls: ['./trade-discount.component.scss']
})
export class TradeDiscountComponent implements OnInit {
  @Input() row:any =[]
  @Input() productList:any =[]
  @Output('productDataEvent') emitter = new EventEmitter(); 
  tradePriceDiscount:number = 0
  constructor() { }

  ngOnInit(): void {
    console.log(this.row)
  }

  ngOnChanges(change:SimpleChange){
    console.log(this.row, this.productList)
    if(this.productList.length > 0 ){
      this.checkTradeDiscount(this.productList)
    }
  }


  checkMinimumAmount:number =0
  checkTradeDiscount(data){

    let brandData = data.map(e=> {
      let obj = {
        brandId:e.brandId,
        brandName:e.brandName
      }
      return obj
    })

    console.log(brandData,"brandData-check")

    let uniqueArray = brandData.filter((value, index) => brandData.indexOf(value.brandId) === index);
    console.log(uniqueArray,"check")
    brandData.forEach(b=>{
      data.forEach(e=>{
        if(Number(e.brandId) == Number(b.brandId)){
          console.log(e)
          if(e?.gradeDetail){
            if(e.gradeDetail?.minimumAmount == 0){
              e.tradeDiscountPrice = Number(e.distributionBeforeTaxRate) - Number((e.distributionBeforeTaxRate * e.gradeDetail.gradePercentage)/100) | 0
              this.tradePriceDiscount =e.tradeDiscountPrice | 0
            }
            else 
            {
              console.log("minimume amount is more then 0")
              this.checkMinimumAmount = this.checkMinimumAmount +  (Number(e.distributionBeforeTaxRate) * Number(e.qty))
              console.log(this.checkMinimumAmount ,"checkMinimumAmount ")
              if(e.gradeDetail?.minimumAmount < this.checkMinimumAmount){
                e.tradeDiscountPrice = Number(e.distributionBeforeTaxRate) - Number((e.distributionBeforeTaxRate * e.gradeDetail.gradePercentage)/100) | 0
                this.tradePriceDiscount =e.tradeDiscountPrice | 0
              }
              this.emitter.emit(data)
              return data
            }
          }
        }
      })
    })
    
    // data.
  }

}
