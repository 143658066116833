<!-- <mat-form-field   floatLabel="always" appearance="outline" class="full-width radio-container" > -->
  <mat-label mat-label> {{ config.label}} </mat-label>
<mat-radio-group   (change)="onChange($event.value)"   [disabled]="disabled" [required]="config.required" [(value)]="selected">
    <mat-radio-button #button class="radio-button"  *ngFor="let item of items" [value]="item[config.key]">
        <span class="wrap-label-text">
        {{item[config.displayKey]}}
      </span>
    </mat-radio-button>

</mat-radio-group>

  <!-- <mat-hint>Choose something</mat-hint>
    <mat-error> 'error' </mat-error> -->
  <!-- </mat-form-field> -->
  