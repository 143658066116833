<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author: Aman Mishra
 Original Date: 3 June 2021 -->


<footer class="main-footer">
    <div class="container-fluid">
        <div>
            Copyright © 2021 Kritin Digital Solutions All rights reserved.
        </div>
    </div>
</footer>