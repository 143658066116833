// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl:'https://jblights.kritin.in/api/', 
  // apiUrl:'http://localhost:7000/api/' ,
  firebase:{
    apiKey: "AIzaSyB-VCJyKcb4-AYUTgB74hXj8Fqi9KyKhJw",
    authDomain: "jbligts-admin.firebaseapp.com",
    projectId: "jbligts-admin",
    storageBucket: "jbligts-admin.appspot.com",
    messagingSenderId: "641519310790",
    appId: "1:641519310790:web:ee532f6ba63407b96144c7",
    measurementId: "G-X7H6YD4FFJ"
  
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
