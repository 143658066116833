<mat-form-field appearance="outline">
  <mat-label> {{label}} </mat-label>
  <input matInput #input [type]="hide ? 'password' : 'text'" [ngClass]="(controlDir && controlDir.control && controlDir.control.touched) ?
  !controlDir.control.valid ? 'is-invalid' : 'is-valid' : null" required (input)="onChange($event.target.value)"
    (blur)="onTouched()" placeholder="{{label}}">
  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hide">
    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>
  <mat-hint *ngIf="(controlDir && controlDir.control && !controlDir.control.valid
&& controlDir.control.touched)">
    <span class="error" *ngIf="controlDir.control.errors?.required">{{label}} is required</span>
    <span class="error" *ngIf="(!controlDir.control.errors?.required && controlDir.control.invalid)">{{label}} is
      Invalid</span>

    <span class="error" *ngIf="controlDir.control.errors.mustMatch"> & password do match
    </span>
  </mat-hint>
</mat-form-field>