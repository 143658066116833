<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author: Jayant Verma
 Original Date: 7 mar 2022 -->

<section class="main-body p-0">
    <mat-card class="mainCard tab_table p-0">
    <div class="image-div">
        <figure>
            <img src="../../../../assets/images/No-data-img/data-img-4.png" alt="no-data">
            <!-- <p>Please Try Again Later</p> -->
        </figure>
    </div>
    </mat-card>
</section>
