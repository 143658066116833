import { IDeleteInvoiceLineItems } from "src/app/core/interfaces/order/order-management/delete-invoice-lineitem";

export class DeleteInvoiceLineItemsModel implements IDeleteInvoiceLineItems {
    
    orderId:number;
    invoiceId:number;
    productId:number;
    invoiceDetailId:number;
    remark:string;
    actionbyLoginUserId:number;
    actionbyUserTypeEnumId:number;
}  