<mat-form-field appearance="outline">
  <mat-label> {{label}} </mat-label>
  <input matInput   [min]="minDate" [max]="maxDate"  [disabled]="disabled" (dateChange)="onDateChange($event.target.value)" (dateChange)="onChange($event.target.value)" [matDatepicker]="picker" [(ngModel)]="selected">
  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="disabled"  ></mat-datepicker-toggle>
  <mat-datepicker #picker ></mat-datepicker>
  <mat-hint *ngIf="(controlDir && controlDir.control && !controlDir.control.valid
  && controlDir.control.touched)">
    <span class="error" *ngIf="controlDir.control.errors?.required">{{label}} is required</span>
    <span class="error" *ngIf="(!controlDir.control.errors?.required && controlDir.control.invalid)">{{label}} is
      Invalid</span>
  </mat-hint>
  

</mat-form-field>


<!-- <mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input matInput [matDatepicker]="picker"  [(ngModel)]="selected"   [min]="minDate" [max]="maxDate"   (dateChange)="onDateChange($event.target.value)">
  <mat-datepicker-toggle matSuffix [for]="picker"  [disabled]="disabled" ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint *ngIf="(controlDir && controlDir.control && !controlDir.control.valid
  && controlDir.control.touched)">
    <span class="error" *ngIf="controlDir.control.errors?.required">{{label}} is required</span>
    <span class="error" *ngIf="(!controlDir.control.errors?.required && controlDir.control.invalid)">{{label}} is
      Invalid</span>
  </mat-hint>
</mat-form-field> -->