<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author: Lokesh Rajput
 Original Date: 21 June 2021 -->
<div *ngIf="data.length==0">
  <app-no-data></app-no-data>
</div>
<div class="historyTableMainDiv" [class]="class" *ngIf="data.length!==0">
  <div class="table_main_div">
    <div class="tableSubHeader" *ngIf="showHeading">
      <label class="tableTitle">History</label>
    </div>
    <!--  sub header start-->
    <div class="row m-0 tableHead">
      <div class="col-sm-6" *ngIf="isSearchable">
        <div class="searchInputWrapper">
          <mat-icon matSuffix class="searchInputIcon">search</mat-icon>
          <input type="text" (keyup)="applyFilter($event.target.value)" placeholder="Search ..." class="searchInput">
        </div>
      </div>
      <div class="col-sm-6 text-end mb-2" [class.col-md-12]="!isSearchable">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
          <mat-button-toggle [excelExport]="data" [fileName]="excelName" aria-label="excel" title="Download Excel">
            <mat-icon fontSet="material-icons-outlined">description</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle aria-label="excel" title="Download Pdf" (click)="pdf()">
            <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon>
          </mat-button-toggle>

        </mat-button-toggle-group>
      </div>
    </div>
    <!--  sub header end-->
    <!-- grid table start --> 
    <div class="mainTable history_table">
      <table mat-table matSort [dataSource]="dataSource" [ngClass]="showSearch ? 'openSearch' : ''" class="Data_table">
        <ng-container *ngFor="let col of tableCols; let i = index" [matColumnDef]="col.key">
          <!-- thead start-->
          <th mat-header-cell *matHeaderCellDef [disabled]="!col.sort" class="{{col.key}}">
            <span *ngIf="col.key !== 'select'" [disabled]="!col.sort" [mat-sort-header]>
              {{ tableCols[i]["display"] }}
            </span> 
          </th>
          <!-- thead end-->
          <!-- tbody start-->
          <td mat-cell *matCellDef="let element ;let j =index" class="{{col.key}}"> 
            <!--  Checking if it's a normal column or column with specific config -->
            <ng-container *ngIf="!col.config; else hasConfig">
              <span class="text-capitalize">
                {{ element[col.key] }} 
              </span>
            </ng-container> 
            <!-- If this column has a config object so we will display depending on our setted config -->
            <ng-template #hasConfig>
              <ng-container>
                <span class="text-capitalize pointer" *ngIf="col.config.isClickAble" (click)="rowClickEvent(element)">
                  <span *ngIf="!col.config.isDate">
                    {{ element[col.key] }}

                  </span>
                  <ng-container *ngIf="col.config.isDate">
                    {{ element[col.key] | date: col.config.format }}
                  </ng-container>

                </span>
              </ng-container>

              <ng-container *ngIf="col.config.isInputType">
                <input type="text" class="form-control input-sm" [(ngModel)]="element[col.key]"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');" min=0 required [disabled]="true" />

              </ng-container>
              <!-- if this attribute is a boolean, we will show values from config object depending on his state -->
              <ng-container *ngIf="col.config.isBoolean">
                {{ showBooleanValue(element, col) }}
              </ng-container>
              <ng-container *ngIf="col.config.isIndex">
                {{j+1}}
              </ng-container>
              <!-- if this attribute is a date, we will format it depending on format value in config object -->
              <ng-container *ngIf="col.config.isDate && !col.config.isClickAble">
                {{ element[col.key] | date: col.config.format }}
              </ng-container>
              <ng-container *ngIf="col.config.isInner">
                {{ element[col.key][col.config.innerKey]}}
              </ng-container>
              <!-- if this column is action, we will loop through each action and show it as a mat-button -->
              <ng-container *ngIf="col.config.isAction">
                <!-- <button *ngFor="let action of col.config.actions" (click)="emitter.emit(action)" mat-button>{{action}}</button> -->
                <!-- <button mat-icon-button *ngFor="let action of col.config.actions" (click)="actionHandler(action,element)">
              <mat-icon><span class="{{action}}">{{action}}</span></mat-icon>
            </button> -->
              </ng-container>
              <ng-container *ngIf="col.config.isStatus">

                <span [ngClass]="element[col.key] === 'Active' ? 'active' : 'inActive'"> {{ element[col.key] }} </span>
              </ng-container>
            </ng-template>
          </td>
          <!-- tbody end-->
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="keys; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: keys"></tr>
      </table>
      <div *ngIf="!dataSource.data.length" class="text-center no_data">
        <h5 class="mb-0">No Record Found</h5>
      </div>
      <!-- pagination start -->
      <mat-paginator [hidden]="!dataSource.data.length" [pageSizeOptions]="[7, 10, 25, 100]" showFirstLastButtons
        class="mat-paginator-sticky pagination_set">
      </mat-paginator>
      <!-- pagination end -->
    </div>
    <!-- grid table end -->
  </div>
</div>