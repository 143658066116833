
   <div class="row mb-5">
    <div class="col-md-6 ps-0">
        <h2>Jai Bharat Elec. Co.</h2>
        <ul class="list-group mt-2">
            <li class="list-group-item">2-Jail Road Indore-452001</li>
            <li class="list-group-item">Phone No. : 0731-2543606</li>
            <li class="list-group-item">E-mail : jaibharatinodre@gmail.com</li>
            <li class="list-group-item">GST No :- 23AEOPT3922NIZY</li>
        </ul>
    </div>
    <div class="col-md-6 text-end pe-0">
        <h2 class="sale-invoice">Sale Invoice</h2>
        <ul class="list-group">
            <li class="list-group-item">
                <h4>Invoice No. #{{invoice.id}}</h4>
            </li>
            <li class="list-group-item">Invoice Date : {{invoice.createdDate | date:'dd-MM-yyyy'}}</li>
        </ul> 
    </div>
    <div class="col-md-12 px-0">
       
    </div>
</div>
<hr>
<!-- DETIAL SECTION -->
<div class="row">
    <div class="col-md-4 ps-0">
        <ul class="list-group">
            <li class="list-group-item">Name & Billing Address of Consignee:</li>
            <li class="list-group-item">
                <h2>{{invoice.customerName}}</h2>
            </li>
            <li class="list-group-item">{{invoice.address}}</li>
            <li class="list-group-item">Phone: {{invoice.mobile}}</li>
            <li class="list-group-item">State : {{invoice.stateName}}</li>
            <li class="list-group-item">GSTIN: {{invoice.gstNumber}}</li>
            <li class="list-group-item">DL No.: </li>
        </ul>
    </div>
    <div class="col-md-4" *ngIf="invoice.orderNameId!=107">      
        <ul class="list-group" *ngIf="invoice.orderTypeStatus !== 'Back Date Invoice'">
            <li class="list-group-item">Name & Shipping Address of Consignee:</li>
           
          
            <li class="list-group-item">
                <h2>{{address.contactPersonName}}</h2>
            </li>
            <li class="list-group-item">{{address.address}}</li>
            <li class="list-group-item">Phone: {{address.mobileNumber}}</li>
            <li class="list-group-item">State : {{address.stateName}}</li>
            <li class="list-group-item">GSTIN: {{invoice.gstNumber}}</li>
            <li class="list-group-item">DL No.: </li>
        </ul>
    </div>
    <div class="col-md-4 pe-0">
        <ul class="list-group">
            <li class="list-group-item">Customer Type : {{invoice.userType}}</li>
            <!-- <li class="list-group-item">
                <h4>Invoice No. #{{invoice.id}}</h4>
            </li>
            <li class="list-group-item">Invoice Date : {{invoice.createdDate | date:'dd-MM-yyyy'}}</li> -->
            <li class="list-group-item mt-3">
                <h4>Order No. : #{{invoice.orderId}} </h4>
            </li>
            <li class="list-group-item">Order Date : {{invoice.orderDate | date:'dd-MM-yyyy'}}</li>
            <li class="list-group-item">Order Type : {{invoice.orderTypeStatus }}</li>

        </ul>
    </div>
</div>
<!-- DETIAL SECTION END -->
<hr>
<!-- TABLE SECTION -->
<div class="row">
    <div class="col-md-12 px-0 mb-4">
        <table>
            <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>HSN</th>
                <th> Qty</th>
                <th>Free Qty</th>
                <th>Before Tax Rate</th>
                <th>Sale Rate</th>
                <th>Sale Disc %</th>
                <th>T Disc %</th>
                <th>Other Disc %</th>
                <th>Total </th>
                <th>Taxable Amt.</th>
                <th>CGST%</th>
                <th>SGST%</th>
                <th>IGST%</th>
            </tr>
            <tr *ngFor=" let item of tableArray ; index as i">
                <td>{{i+1}}</td>
                <td>{{ item.productName }}</td>
                <td>{{ item.hsnCode }}</td>
                <td>{{item.dispatchQuantity}}</td>
                <td>{{item.freeNoOfItem}}</td>
                <td>{{item.beforTaxRate|number : '1.2-2'}}</td>
                <td>{{item.afterTaxRate|number : '1.2-2'}}</td>
                <td>{{item.saleDiscount|number : '1.2-2'}}</td>
                <td>{{item.discountPer|number : '1.2-2'}}</td>
                <td>0</td>
                <td>{{item.Total|number : '1.2-2'}}</td>
                <td>{{item.beforTaxRate*item.dispatchQuantity|number : '1.2-2'}}</td>
                <td>{{item.gstItem.CGST|number : '1.2-2'}}</td>
                <td>{{item.gstItem.SGST|number : '1.2-2'}}</td>
                <td>{{item.gstItem.IGST|number : '1.2-2'}}</td>
                <td></td>
            </tr>
           

            <tr>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{{totalQty}}</td>
                <td>{{totalFreeQty}}</td>
                <td>{{totalBeforeRate|number : '1.2-2'}}</td>
                <td>{{totalAfterRate|number : '1.2-2'}}</td>
                <td></td>
                <td></td>
                <td>0</td>
                <td>{{total|number : '1.2-2'}}</td>
                <td>{{totalBeforTaxAmount|number : '1.2-2'}}</td>
                <td>{{totalBeforTaxAmount *CGST/100|number : '1.2-2'}}</td>
                <td>{{totalBeforTaxAmount *SGST/100|number : '1.2-2'}}</td>
                <td>{{IGST|number : '1.2-2'}}</td> 
            </tr>
        </table>
    </div>
    <div class="col-md-12 px-0">
        <div class="second-table">
            <table>
                <tr>
                    <th>HSN CODE</th>
                    <th>Qty</th>
                    <th>TAXRATE</th>
                    <th>TAXABLE VALUE</th>
                    <th>SGST</th>
                    <th>CGST</th>
                    <th>IGST</th>
                    <th>TOTAL TAX</th>
                </tr>
                <tr *ngFor=" let item of invoiceHsnTable ; index as i">
                    <td>{{item.HsnCode}}</td>
                    <td>{{item.qty}}</td>
                    <td>{{item.taxRate}}</td>
                    <td>{{item.taxableValue|number : '1.2-2'}}</td>
                    <td>{{item.SGST|number : '1.2-2'}}</td>
                    <td>{{item.CGST|number : '1.2-2'}}</td>
                    <td>{{item.IGST|number : '1.2-2'}}</td>
                    <td>{{item.totalTax|number : '1.2-2'}}</td>
                    <!-- <td>Total</td>
                    <td>Gross Amount:</td>
                    <td>56349.00</td> -->
                </tr>
            </table>
        </div>
    </div>
</div>
<div class="row mt-3 table-row">
    <div class="col-md-6 pe-0">
        <ul class="list-group table-list-grp">
            <li class="list-group-item">Amt. of Tax Reverse Charges</li>
            <li class="list-group-item">
               {{amountIntoWords}}
            </li>
            <li class="list-group-item">Note: </li>
            <li class="list-group-item">Jai Bharat Electic Co.</li>
            <li class="list-group-item">AXis Bank</li>
            <li class="list-group-item">Branch: Sapna Sangita Road, Indore</li>
            <li class="list-group-item">Current Account: 50301000003414, IFSC Code: UTIB000503</li>
        </ul>
    </div>
    <div class="col-md-6 text-end ps-0">
        <ul class="list-group table-list-grp">
            <li class="list-group-item">NET. AMOUNT : {{totalBeforTaxAmount|number : '1.2-2'}}</li>
            <!-- <li class="list-group-item">DISCOUNT : 0</li> -->
            <li class="list-group-item">
                CGST: {{totalTaxAble *CGST/100|number : '1.2-2'}}
            </li>
            <li class="list-group-item">SGST : {{totalBeforTaxAmount *SGST/100|number : '1.2-2'}}</li>
            <li class="list-group-item">IGST : {{IGST|number : '1.2-2'}}</li>
            <li class="list-group-item">GROSS AMOUNT : {{total|number : '1.2-2'}}</li>

        </ul>
    </div>
</div>
   
