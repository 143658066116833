<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author: Aman Mishra
 Original Date: 3 June 2021 -->
<router-outlet>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" [fullScreen] = "true" type = ""> 
        <div class="loading">
            <img src="../assets/images/bulb-loader.gif" alt="loader">
            <h5>Loading<span>...</span></h5>
        </div>    
    </ngx-spinner>
</router-outlet>