<ng-container *ngIf="noData">
    <div class=" row  d-flex ">
        <div class="col-md-12 sellPrice" *ngIf="sellingPrice !==0"><span
                class="SellPriceAmount" (click)="infoShow = !infoShow">₹ {{sellingPrice}}</span>
            <span *ngIf="sellingPrice !== sellingPriceExDiscount ">
                <!-- <span  class="alert alert-info p-0 ms-1 offerLabel"> Offer
                    <mat-icon>info</mat-icon></span> -->
                <ng-container *ngIf="data?.length > 0 ; else noScheme ">
                    <!-- <label>Offers</label> -->
                    <div *ngIf="infoShow" class="scheme  mb-0">
                        <ul>
                            <li *ngFor="let scheme of data">
                                <span class="mb-0">
                                    {{scheme.schemeName }}
                                 </span>
                            </li>
                        </ul>
                    </div>
                </ng-container>
                <ng-template #noScheme>
                   <p  *ngIf="infoShow" class="noScheme">
                    No Scheme Available
                   </p> 
                </ng-template>
            </span>
        </div>
        </div>
</ng-container>