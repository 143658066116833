import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
// import { userAccess } from "../constants/user-access";

export class UserAccess {
  checkPageAccess(page) {
    // userAccess['']
    let userAccessData = JSON.parse(sessionStorage.getItem('userAccessData'));
    if(userAccessData == null) {
      return true;
    }
    console.log("userAccessData = ", userAccessData);
    let flag = false;
    userAccessData.forEach(module => {
      if(module.data.find(x => x.page_name == page) != undefined) {
        let pageData = module.data.find(x => x.page_name == page);
        if(pageData.add || pageData.view || pageData.edit || pageData.delete) {
          flag = true; 
        }
      } 
    });
    console.log("flag = ", flag);
    return flag;
  }
}
@Injectable()

export class UserAccessControlAuthGuard implements CanActivate {
  constructor(
      private router: Router,
      private UserAccess: UserAccess
    ) { }
  canActivate(route: ActivatedRouteSnapshot) {
    return this.UserAccess.checkPageAccess(route.data[0].page);
  }
}