import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @Input() data:any = []
  @Input() tableArray:any = [];
  @Input() invoiceHsnTable:any = [];
  invoice:any
  
  product:any
  address:any
  isPartner:boolean = false
  price:any=[]
  total: number = 0;
  totalTaxAble: number =0;
  totalBeforeRate:  number =0;
  totalAfterRate:  number =0;
  totalQty:  number =0;
  totalFreeQty:  number =0;
  totalBeforTaxAmount:  number =0;
  totalTaxAmount:  number =0;
  totalAfterTaxAmount:  number =0;
  amountIntoWords: string;  IGST: any =0;
  CGST: any =0;
  SGST: any=0;

  constructor() {
   

   }

  ngOnInit(): void {
    console.log('tableArray check', this.tableArray, "data",)
    this.invoice = this.data[0]
    console.log(this.invoice,"data")
    this.backDateInvoice(this.invoice)
    this.address= this.invoice.shippingOrderAddress[0]
    this.product = this.invoice.getInvoiceDetailfunctionArray
    if(this.invoice.becamePartnerStatus == 'Approved'){
        this.isPartner = true
        this.product.forEach(e=>{
          this.price.push(e.distributionAfterTaxRate)
          this.price.push(e.distributionBeforeTaxRate)
        })

      }
    else{
      this.product.forEach(e=>{
      
        this.price.push(e.afterTaxRate)
        this.price.push(e.beforeTaxRate )

        console.log(this.price,"price")
      })
    }
    this.calculateTotalAmount()
  }


  calculateTotalAmount() {
    this.tableArray.forEach(element => {
      console.log('ele', element)
      const dispatchQty = Number(element.dispatchQuantity)
      this.total = Math.round(this.total + dispatchQty*Number(element.afterTaxRate))
      this.totalTaxAble = this.totalTaxAble + dispatchQty*Number(element.taxable)
      this.totalBeforeRate = this.totalBeforeRate + Number(element.beforTaxRate)
      this.totalAfterRate = this.totalAfterRate + Number(element.afterTaxRate)
      this.totalQty = Number(this.totalQty) + Number(element.dispatchQuantity)
      this.totalFreeQty = Number(this.totalFreeQty) + Number(element.freeNoOfItem)
      this.totalBeforTaxAmount = Number(this.totalBeforTaxAmount) + (element.beforTaxRate * element.dispatchQuantity)-(element.beforTaxRate*element.saleDiscount/100)
      this.totalTaxAmount = (Number(this.totalTaxAmount) + Number(element.taxRate)) 
      this.totalAfterTaxAmount = Number(this.totalAfterTaxAmount) +  (element.afterTaxRate * element.dispatchQuantity)-(element.afterTaxRate*element.saleDiscount/100)
      this.amountIntoWords = this.convertNumberToWords(this.total)
      this.IGST = this.IGST + Number(element.dispatchQuantity*Number(Number(element.beforTaxRate) * Number(element.gstItem.IGST/100)))
      this.CGST =  element.gstItem.CGST
      this.SGST =  element.gstItem.SGST
      
    });
    console.log(this.tableArray,"this.tableArray")
  }


  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j] as any);
            n_array[i] = 0;
          }
        }
      }
      let value;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string + 'Rupees';
  }


   backDateInvoice(invoice){
    if(invoice.orderTypeStatus == 'Back Date Invoice'){
      console.log(invoice)

      console.log(invoice.getInvoiceDetailfunctionArray,"invoice.getInvoiceDetailfunctionArray")
      let a3 = invoice.getInvoiceDetailfunctionArray.map(t1 => ({...t1, ...this.tableArray.find(t2 => t2.productId== t1.productId)}))

        console.log(a3,"a3")
        this.tableArray = []


         a3.forEach(e=>{
         e.beforTaxRate = e.per_product_price
         e.dispatchQuantity = e.noOfProduct
         e.afterTaxRate =( (e.beforTaxRate * e.tax_percents)/100 ) + e.beforTaxRate
      })
       this.tableArray = a3
      console.log(this.tableArray, "this.tableArray")
    }
  }


}
