
   <section class="main-body-confirm">
     <div class="rowj">
      <button mat-icon-button class="colseBtn">
        <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
     </div>
   
    <h4 class="center">{{data.data}} </h4>
    <form [formGrop]="remarkForm" *ngIf="remark">
      <div class="row" >
        <div class="col-sm-12">
            <textarea placeholder="Remark"></textarea>
            <div *ngIf="submitted && remarkForm.controls.remark.errors" class=text-danger>
              <div *ngIf="submitted && remarkForm.controls.remark.errors?.required">remark is required</div>
            </div>

        </div>
       </div>
    </form>
   
  
    <mat-card-actions>
        <button (click)="noClick()" class="btn secondary">
        No
      </button>

      <button (click)="yesClick()" class="btn btn-primary">
        Yes
      </button>
    </mat-card-actions>
  </section>