<mat-form-field  appearance="outline"
                [ngClass]="jpCustomFormFieldClass">
  <mat-label>{{label}}</mat-label>
  <input matInput
       
         (click)="_openDatepickerOnClick(datepicker)"
         (keydown.arrowdown)="_openDatepickerOnClick(datepicker)"
         (keydown.enter)="_openDatepickerOnClick(datepicker)"
         [formControl]="_inputCtrl"
         [matDatepicker]="datepicker"
         [max]="max"
         [min]="min"
         readonly>

<mat-datepicker #datepicker 
                (yearSelected)="_yearSelectedHandler($event,datepicker)"
                startView="multi-year">
</mat-datepicker>