import { Invoice } from "src/app/core/interfaces/order/order-management/invoice-master";

export class InvoiceModel implements Invoice {
    
    invoiceId:number;
    orderId:number;
    customerId:number;
    shopId:number;
    paymentMode:number; 
    paymentType:string; 
    subtotal:number;
    discountAmount:number; 
    totalTax:number;
    otherCharges:number; 
    actionbyLoginUserId:number; 
    actionbyUserTypeEnumId:number;
    statusEnumId:number;
    sale_discount: number;
    actionOnDate:string;
    invoiceDetail: Array<
                          { 
                            invoice_detail_id : number;
                            product_id : number;
                            dispatch_quantity  : number;
                            per_product_price : number;
                            tax_amount : number;
                            free_no_of_item :number;
                            sale_discount :number;

                            }
                    >


}
  